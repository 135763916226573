import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../layout/layout';
import { navigate, Redirect } from "@reach/router";
import ReadMoreAndLess from 'react-read-more-less';
import ReactHtmlParser from "react-html-parser";
import ScrollAnimation from 'react-animate-on-scroll';
import noSectionImage from '../assets/images/no-image-thumbnail.png';
import noSectionMedImage from '../assets/images/no-image-medium.png';
import bannerImage from '../assets/images/no-image-big.png';
import ScaleLoader from "react-spinners/ScaleLoader";
import NavMenu from '../components/navMenu';
import { isLoggedIn } from "../services/auth";
import { DIZZEE_LOGIN } from "gatsby-env-variables";
import { window } from 'browser-monads';
import axios from "axios";
import { DIZZEE_API } from "gatsby-env-variables";
import Modal from 'react-bootstrap/Modal';
import closeicon from '../assets/images/close-icon.svg';
import { umaTutorials } from "../utlis/globals";
import { getCookie } from "../utlis/cookieMgmt";
import reloadIfAuthExpired from '../services/auth';
import amplitude from 'amplitude-js';

const dizzeeLogin = `${DIZZEE_LOGIN}&client=umx&redirect=${encodeURIComponent(window.location.href)}`;
const faqUrl = 'https://help.musicinsights.com/hc/en-us/categories/115000183374-Artists';
const contactForm = 'https://help.musicinsights.com/hc/en-us/requests/new?ticket_form_id=360001942113';

export const query = graphql`
{
  WordPress {
    pageBy(uri: "uma") {
      title
      date
      um_pages_content {
        umPagesContent {
          __typename
          ... on WordPress_Page_UmPagesContent_UmPagesContent_BannerSection {
            bannerImage {
              sourceUrl
            }
            bannerSubTitle
            bannerTitle
          }
          ... on WordPress_Page_UmPagesContent_UmPagesContent_AboutSection {
            sectionTitle
            description
            addButton {
              buttonLink
              buttonTitle
            }
          }
          ... on WordPress_Page_UmPagesContent_UmPagesContent_WhatsNew {
            sectionData {
              buttonLink
              buttonTitle
              detailPageLinkSlug
              displayReadmoreOrButton
              image {
                sourceUrl
              }
              readMoreLink
              readMoreLinkTitle
              selectButtonOrLink
              shortDescription
              title
            }
          }
          ... on WordPress_Page_UmPagesContent_UmPagesContent_CoreFeaturesSection {
            sectionTitle
            sectionData {
              buttonTitle
              contentPosition
              displayButton
              link
              sectionTitle
              selectImage
              shortDescription
              uploadMultipleImage {
                uploadImage {
                  sourceUrl
                }
              }
              uploadSingleImage {
                sourceUrl
              }
            }
          }
        }
      }
    },
    menu(id: "dGVybTo5") {
      name
      menuItems {
        nodes {
          label
          menu_data {
            externalLink
            sectionId
            svg {
              sourceUrl
            }
          }
        }
      }
    },
    themeGeneralSettings {
      theme_settings {
        submitARequestForm {
          typeOfRequest {
            requestType
          }
        }
      }
    }

  }
}
`

const UMA = ({ data }) => {

  const [isSticky, setSticky] = useState(false);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState();
  const [menu, setMenu] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  // header scroll transition
  const handleScroll = (e) => {
    var headerH = document.querySelector('header') ? document.querySelector('header').offsetHeight : "";
    setSticky(window.scrollY > headerH ? true : false);
  };

  const validToken = () => {
    if (getCookie("swiftTrends")) {
      axios
        .get(`${DIZZEE_API}/api/whoami?app=swiftTrends`, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            application: "swiftTrends"
          },
        })
        .then((response) => {
          // console.log('WHOAMI token status', response);
          if ((response.data && response.data.token) && response.status === 200) {
            axios
            .get(`${DIZZEE_API}/action/user/data?apps=all`, {
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
                application: "swiftTrends"
              },
            })
            .then((resp) => {
              if (resp.data.info === "SUCCESSFULLACTION") {
                this.setState({ user: resp.data.userInfo });
              }
            });
          }
        })
        .catch(err => {
          console.log('error', err);
          reloadIfAuthExpired(err);
        });
    }
  }

  useEffect(() => {
    amplitude.getInstance().logEvent('PAGE_VIEW_UMA');
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
    validToken();
  }, []);

  const pageTitle = data && data.WordPress && data.WordPress.pageBy && data.WordPress.pageBy.title ? data.WordPress.pageBy.title : "";
  let bannerSection = {};
  let aboutSection = {};
  let whatNew = {};
  let featureSection = {};
  if (data) data.WordPress && data.WordPress.pageBy && data.WordPress.pageBy.um_pages_content && data.WordPress.pageBy.um_pages_content && data.WordPress.pageBy.um_pages_content.umPagesContent.filter(each => {
    if (each && each.__typename === "WordPress_Page_UmPagesContent_UmPagesContent_BannerSection") {
      bannerSection = each;
    }
    if (each && each.__typename === "WordPress_Page_UmPagesContent_UmPagesContent_AboutSection") {
      aboutSection = each;
    }
    if (each && each.__typename === "WordPress_Page_UmPagesContent_UmPagesContent_WhatsNew") {
      whatNew = each;
    }
    if (each && each.__typename === "WordPress_Page_UmPagesContent_UmPagesContent_CoreFeaturesSection") {
      featureSection = each;
    }
  });
  let headerBlog = data && data.WordPress && data.WordPress.menu;
  let sectionReasons = data && data.WordPress && data.WordPress.themeGeneralSettings && data.WordPress.themeGeneralSettings.theme_settings && data.WordPress.themeGeneralSettings.theme_settings.submitARequestForm && data.WordPress.themeGeneralSettings.theme_settings.submitARequestForm.typeOfRequest;
  sectionReasons = sectionReasons && sectionReasons.length && sectionReasons.map(each => {
    return ({ value: each.requestType, label: each.requestType })
  })
  if (isLoggedIn()) {
  return (
    <React.Fragment>
      {!Object.keys(data).length || (data.WordPress && data.WordPress.pageBy && data.WordPress.pageBy.um_pages_content.length === 0) ?
        <div className="product-loader"><ScaleLoader color={'#141618'} size={150} /></div> :
        <Layout uma="inner-page" headerColor="header-UMA" title={pageTitle} seoTitle={'UMA'} seoDes={"x-musix uma page"}>
          <div className={`category-menu-outer uma-category-menu-outer ${isSticky ? 'header-category-fixed' : ''}`}>
            <div className="container">
              <NavMenu isSticky={isSticky} headerBlog={headerBlog} sectionReasons={sectionReasons} faq={faqUrl} contact={contactForm} showTutorials={handleShow} />
            </div>
          </div>

          {/* <h3>My page UMA</h3> */}
          {bannerSection &&
            <section className="common-banner uma-banner">
              <div className="container">
                <div className="common-banner-content">
                  <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                    <h1 dangerouslySetInnerHTML={{ __html: bannerSection.bannerTitle }} />
                  </ScrollAnimation>
                  <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                    <h4 dangerouslySetInnerHTML={{ __html: bannerSection.bannerSubTitle }} />
                  </ScrollAnimation>
                  <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                    <div className="common-banner-img">
                      <img src={bannerSection.bannerImage && bannerSection.bannerImage.sourceUrl ? bannerSection.bannerImage.sourceUrl : bannerImage} alt={bannerSection.bannerTitle} />
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </section>}

          {aboutSection &&
            <section className="common-about bg-base-black">
              <div className="container">
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <div className="common-about-content">
                    <h3 dangerouslySetInnerHTML={{ __html: aboutSection.sectionTitle }} />
                    <p dangerouslySetInnerHTML={{ __html: aboutSection.description }} />
                    <div className="app-btn-group">
                      {aboutSection && aboutSection.addButton && aboutSection.addButton.length ? aboutSection.addButton.map((each, id) => {
                        let title = each && each.buttonTitle;
                        let buttonLink = each && each.buttonLink;
                        return (<a href="javascript:;" className="btn btn-brd btn-brd-white" key={id} onClick={() => navigate(`${buttonLink}`)}><span>{title}</span></a>)
                      }) : ""}
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </section>}

          {whatNew &&
            <section className="common-whatsnew" id="whatsnew">
              <div className="container">
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <div className="common-whatsnew-content">
                    <h3>What’s New</h3>
                    <div className="whats-new-block-outer" >
                      {whatNew && whatNew.sectionData ? whatNew.sectionData.map((name, id) => {
                        let description = name && name.shortDescription;
                        let title = name && name.title ? ReactHtmlParser(name.title) : "";
                        let image = name && name.image && name.image.sourceUrl ? name.image.sourceUrl : noSectionImage;
                        // let slug = name && name.detailPageLinkSlug
                        return (
                          // <div id="whatsabout" className="whats-new-block" key={id} onClick={() => navigate(`/blog-details/?slug=${slug}`)} >
                          <div id="whatsabout" className="whats-new-block" key={id}>
                            <div className="whats-new-img">
                              <img src={image} alt={title} />
                            </div>
                            <h6 className="color-black">{title}</h6>
                            <div className="desc">
                              {description ? description : ''}
                            </div>
                          </div>)
                      }) : ""}
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </section>
          }

          {featureSection &&
            <section id="coreFeature" className="common-core-feature bg-base-black">
              <div className="container">
                <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                  <h2>{featureSection.sectionTitle}</h2>
                </ScrollAnimation>
                {featureSection && featureSection.sectionData && featureSection.sectionData.map((each, id) => {
                  let title = each && each.sectionTitle ? ReactHtmlParser(each.sectionTitle) : "";
                  let description = each && each.shortDescription ? ReactHtmlParser(each.shortDescription) : "";
                  let imagePosition = each && each.contentPosition ? each.contentPosition : 'right';
                  let singleImage = each && each.uploadSingleImage && each.uploadSingleImage.sourceUrl ? each.uploadSingleImage.sourceUrl : '';
                  let images = each && each.uploadMultipleImage && each.uploadMultipleImage.length ? each.uploadMultipleImage : null;
                  let showSingleOrNot = each && each.selectImage ? each.selectImage : noSectionMedImage;
                  return (<div className="core-feature-main" key={id}>
                    <div className={imagePosition === "left" ? "row flex-md-row-reverse align-items-center" : "row align-items-center"}>
                      <div className={imagePosition === "left" ? "col-md-6" : "col-md-8"} style={{ justifyContent: 'center', display: 'flex' }}>
                        <ScrollAnimation animateIn={imagePosition === "right" ? "fadeInLeft" : "fadeInRight"} animateOnce={true}>
                          {showSingleOrNot && showSingleOrNot !== "singleimage" && images && images.length && images !== null ? images.map((each, id) => {
                            let multiImages = each && each.uploadImage && each.uploadImage.sourceUrl ? each.uploadImage.sourceUrl : noSectionMedImage;
                            return (<div className="core-feature-img" key={id}>
                              <img src={multiImages} alt={featureSection.sectionTitle} />
                            </div>)

                          }) : singleImage ? <div className="core-feature-img">
                            <img src={singleImage} alt={featureSection.sectionTitle} />
                          </div> : ""}
                        </ScrollAnimation>
                      </div>
                      <div className={imagePosition === "left" ? "col-md-6" : "col-md-4"}>
                        <ScrollAnimation animateIn={imagePosition === "right" ? "fadeInRight" : "fadeInLeft"} animateOnce={true}>
                          <div className="core-feature-content">
                            <h4 className="color-white">{title}</h4>
                            <p>{description}</p>
                          </div>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>)
                })}
              </div>
            </section>}
        </Layout>}
        <Modal id="tutorialModal" show={show} onHide={handleClose}>
        <Modal.Body>
          <a href="#" className="close-btn">
            <div className="close-btn-img">
              <img src={closeicon} alt="close" onClick={handleClose} />
            </div>
          </a>
          <div style={{ padding: '30px' }}>
            <div className="row">
              <div className="col-md-12">
                <h2 style={{ padding: '15px' }}>Tutorials</h2>
              </div>
            </div>
            <div className="row">
              {umaTutorials.map((x) => {
                return (
                  <div className="col-md-6">
                    <div className="col-md-12" style={{ padding: '15px', color: '#989ca0' }}>{x.title}</div>
                    <div className="col-md-12" style={{ paddingBottom: '30px' }}>
                      <video
                        className="VideoTutorials--content-video"
                        width="100%"
                        height="100%"
                        controls
                        controlsList="nodownload"
                        poster={x.poster}
                        allowFullScreen
                        style={{
                          backgroundColor: '#000000',
                          maxHeight: '480px',
                        }}
                    >
                      <source src={x.url} type="video/mp4" />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className={menu ? "overlay-bg overlay-bg-show" : "overlay-bg"} onClick={() => setMenu(false)}></div>
    </React.Fragment>
  );
} else if (!isLoggedIn()) {
  navigate(dizzeeLogin);
  return null;
}
};


export default UMA
