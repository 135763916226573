const CDN_BASE_URL = 'https://usaws09-umx-cdn-dev.s3.amazonaws.com';

export const umlTutorials = [
    {
        index: 0,
        title: 'UML Alerts Explained',
        url: `${CDN_BASE_URL}/Video/UML_Alerts_v6.mp4`,
        poster: `${CDN_BASE_URL}/img/uml_alerts_poster.jpg`
    },
    {
        index: 1,
        title: 'Spotify Spikes',
        url: `${CDN_BASE_URL}/Video/Streaming+Spikes.mp4`,
        poster: `${CDN_BASE_URL}/img/uml_spikes_poster.jpg`
    },
    {
        index: 2,
        title: 'Spotify Release Comparisons',
        url: `${CDN_BASE_URL}/Video/Spotify+Release+Comparison.mp4`,
        poster: `${CDN_BASE_URL}/img/uml_release_comparison_poster.jpg`
    },
    {
        index: 3,
        title: 'Spotify Playlist Adds',
        url: `${CDN_BASE_URL}/Video/Spotify+Playlist+Adds.mp4`,
        poster: `${CDN_BASE_URL}/img/uml_playlist_add_poster.jpg`
    },
    {
        index: 4,
        title: 'Spotify Milestones',
        url: `${CDN_BASE_URL}/Video/Spotify+Milestones.mp4`,
        poster: `${CDN_BASE_URL}/img/uml_milestones_poster.jpg`
    },
    {
        index: 5,
        title: 'Spotify Chart Peaks',
        url: `${CDN_BASE_URL}/Video/Spotify+Chart+Peaks.mp4`,
        poster: `${CDN_BASE_URL}/img/uml_chart_peaks_poster.jpg`
    },
];

export const umaTutorials = [
    {
      index: 0,
      title: '01: The Basics',
      url: `${CDN_BASE_URL}/Video/uma_the_basics_720p.mp4`,
      poster: `${CDN_BASE_URL}/img/uma_the_basics_poster.png`
    },
    {
      index: 1,
      title: '02: Top Songs & Top Videos',
      url: `${CDN_BASE_URL}/Video/uma_top_songs_and_top_videos_720p.mp4`,
      poster: `${CDN_BASE_URL}/img/uma_top_songs_and_top_videos_poster.png`
    },
    {
      index: 2,
      title: '03: Your Songs',
      url: `${CDN_BASE_URL}/Video/uma_your_songs_720p.mp4`,
      poster: `${CDN_BASE_URL}/img/uma_your_songs_poster.png`
    },
    {
      index: 3,
      title: '04: Know Your Audience',
      url: `${CDN_BASE_URL}/Video/uma_know_your_audience_720p.mp4`,
      poster: `${CDN_BASE_URL}/img/uma_know_your_audience_poster.png`
    },
    {
      index: 4,
      title: '05: Your Top Playlists',
      url: `${CDN_BASE_URL}/Video/uma_your_top_playlists_720p.mp4`,
      poster: `${CDN_BASE_URL}/img/uma_your_top_playlists_poster.png`
    },
    {
      index: 5,
      title: '06: Your Social Media Engagement',
      url: `${CDN_BASE_URL}/Video/uma_your_socials_720p.mp4`,
      poster: `${CDN_BASE_URL}/img/uma_your_socials_poster.png`
    },
    {
      index: 6,
      title: '07: Your YouTube Videos',
      url: `${CDN_BASE_URL}/Video/uma_your_youtube_videos_720p.mp4`,
      poster: `${CDN_BASE_URL}/img/uma_your_youtube_videos_poster.png`
    },
    {
      index: 7,
      title: '08: Turn On Your Alerts',
      url: `${CDN_BASE_URL}/Video/uma_turn_on_your_alerts_720p.mp4`,
      poster: `${CDN_BASE_URL}/img/uma_turn_on_your_alerts_poster.png`
    }
  ];

export const umdTutorials = [
    {
      index: 0,
      title: '01: Using Watchlists and Reports',
      url: `${CDN_BASE_URL}/Video/umd_using_watchlists_reports.mp4`,
      poster: `${CDN_BASE_URL}/img/umd_using_watchlists_reports_poster.png`
    },
    {
      index: 1,
      title: '02: What Are Watchlists? (Summary)',
      url: `${CDN_BASE_URL}/Video/umd_watchlists.mp4`,
      poster: `${CDN_BASE_URL}/img/umd_watchlists_poster.png`
    },
    {
      index: 2,
      title: '03: What Are Reports? (Summary)',
      url: `${CDN_BASE_URL}/Video/umd_reports.mp4`,
      poster: `${CDN_BASE_URL}/img/umd_reports_poster.png`
    }
  ];